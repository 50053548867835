iframe {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%;
}

.btnCor {
    color: white;
    padding: 1rem;
    border: 2px solid #003068;
    border-radius: 1rem;
    background: linear-gradient(#166BCF, #4399FF);
    box-shadow: inset 0px -.5rem 0px #175298, 0px 0px 0px 4px #E1E1E1, rgba(0, 0, 0, 1) 0px 5px 15px;
    font-family: "Nunito", sans-serif;
    text-transform: uppercase;
    text-align: center;
}

.respostaSubmissaoLaranja {

    color: white;
    padding: .3rem;
    border: 2px solid #666800;
    border-radius: 1rem;
    background: linear-gradient(#C0CF16, #FFF743);
    box-shadow: inset 0px -.8rem 0px #8E9817, 0px 0px 0px 4px #E1E1E1, rgba(0, 0, 0, 1) 0px 5px 15px;
    font-family: "Nunito", sans-serif;
    text-transform: uppercase;
    text-align: center;
    user-select: none;
    display: flex;
    border-radius: 1rem;
}

.respostaSubmissaoVermelho {

    color: white;
    padding: .3rem;
    border: 2px solid #680000;
    border-radius: 1rem;
    background: linear-gradient(#CF1616, #FF4365);
    box-shadow: inset 0px -.8rem 0px #981717, 0px 0px 0px 4px #E1E1E1, rgba(0, 0, 0, 1) 0px 5px 15px;
    font-family: "Nunito", sans-serif;
    text-transform: uppercase;
    text-align: center;
    user-select: none;
    display: flex;
    border-radius: 1rem;
}

.respostaSubmissaoVerde {

    color: white;
    padding: .3rem;
    border: 2px solid #286800;
    border-radius: 1rem;
    background: linear-gradient(#16CF1E, #43FF78);
    box-shadow: inset 0px -.8rem 0px #299817, 0px 0px 0px 4px #E1E1E1, rgba(0, 0, 0, 1) 0px 5px 15px;
    font-family: "Nunito", sans-serif;
    text-transform: uppercase;
    text-align: center;
    user-select: none;
    display: flex;
    border-radius: 1rem;
}

.textoEscolha p {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    color: white;

}


.linha-horizontal-3 {
    border: .1rem solid #cccccc;
}

.boxCentroReposta {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 1rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

}

.boxSombra {



    border-radius: 1rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

}

.sombra {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.centro {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    border-radius: 1rem;
    font-size: 1.5rem;
}

.centralizarIcone {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}


.bordaAtividade {
    border: 1px solid #e1e1e1;
    border-radius: .5rem;
    width: 100%;
    padding-left: 1.5rem;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
}

.CenterDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 1rem;
}

.respostaCor {
    border-radius: .5rem;
}

.container {
    height: 5rem;
    display: flex;
    justify-content: center;
    color: white;
    height: 2rem;
    border-radius: .5rem;
    font-size: 2rem;
}

.shapes {
    position: inherit;
    width: 100%;
    height: 100%;
}

.centralizar {
    height: 5rem;
    display: flex;
    justify-content: center;
    color: white;
    height: 2rem;
    border-radius: .5rem;
}

.centralizar2 {
    height: 5rem;
    display: flex;
    justify-content: center;
    color: white;
    height: 2rem;
    border-radius: .5rem;
}

.custom-modal .ant-modal-content {
    padding: 0;
    border-radius: 1rem;
}

.centralizar p {
    align-self: center;
}

.centralizar2 p {
    align-self: center;
}

.centralizar:hover {
    opacity: 0.8;

}

.btnOpacidade:hover {
    opacity: 0.8;
}

.quardado {
    width: 4.5rem;
    height: 2.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.borda {
    border: .1rem;
    border-radius: 1rem;
    border-style: solid;
    border-color: #cccccc;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.linha-horizontal-1 {
    width: 100%;
    border: .1rem solid #cccccc;
    position: relative;
    margin-left: -.5rem;
    padding-right: .4rem;
    margin-bottom: .5rem;
}

.linha-horizontal-4 {
    margin-bottom: .2rem;
    width: 100%;
    border: .1rem solid #cccccc;
    position: relative;
    padding-right: 1.9rem;
    margin-left: -1rem;
    margin-bottom: 1rem;
}

.linha-horizontal-0 {
    margin-bottom: .2rem;
    width: 100%;
    border: .1rem solid #cccccc;
    position: relative;
    padding-right: 1.9rem;
    margin-left: -1rem;
    margin-bottom: 1rem;
}

.linha-horizontal-2 {
    margin-top: 1.5rem;
    margin-bottom: .2rem;
    width: 100%;
    border: .1rem solid #cccccc;
    position: relative;
    padding-right: .4rem;
}

.itemTitulo {
    overflow-wrap: anywhere;
}

.itemTitulo p {
    font-weight: bold;
    margin-bottom: 0;
    margin-top: .1rem;
    font-size: 1.5rem;
}

.itemTitulo span {
    font-size: .8rem;
    color: #727272
}

:where(.css-dev-only-do-not-override-mxhywb).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none
}

:where(.css-dev-only-do-not-override-mxhywb).ant-picker-dropdown .ant-picker-today-btn {
    color: #000000
}

/* :where(.css-dev-only-do-not-override-mxhywb).ant-picker-dropdown .ant-picker-today-btn {
    color: rgb(0, 72, 182);
}

:where(.css-dev-only-do-not-override-mxhywb).ant-picker-dropdown .ant-picker-header-view button {
    color: rgb(0, 72, 182);
}

:where(.css-dev-only-do-not-override-mxhywb).ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th {
    color: rgb(0, 72, 182);
}

:where(.css-dev-only-do-not-override-mxhywb).ant-picker-dropdown .ant-picker-header>button {
    color: rgb(0, 72, 182);
} */