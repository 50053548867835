.divHeader {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

body {
  margin-left: .5rem;
  margin-right: .5rem;
}

.logo {
  margin-bottom: 1rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 2rem;
  border-bottom: 1px solid #000;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.transicao {
  opacity: 0;
  animation: fadeIn 5s ease 5s forwards;
}


.img {
  max-width: 50%;
  max-height: 50%;
  display: block;
  margin: auto;
  margin-left: 2rem;
}

.logo p {

  margin-right: 2rem;
}



iframe {
  border: 0px;
}